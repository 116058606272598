
.launch__banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  background-color: #e85b6a;
  position: absolute;
  top: 25%;
  margin: 0 auto;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 400px) and (max-width: 768px) {
    height: 50%;
    width: 80%; /* Adjust the width for smaller screens */
  }

  @media (min-width: 768px) and (max-width: 1366px) {
    text-align: center;
    height: 50%;
    width: 60%; /* Adjust the width for smaller screens */
  }

  .close__btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    .line {
      width: 25px;
      height: 3px;
      background-color: #fff;
      margin: 5px;
      border-radius: 5px;
    }

    .line:first-child {
      position: relative;
      top: 5px;
      transform: rotate(45deg);
    }

    .line:last-child {
      position: relative;
      bottom: 5px;
      transform: rotate(-45deg);
    }
  }

  .launching__soon {
    span {
      font-size: 4rem;
      font-weight: bold;
      letter-spacing: 3px;
    }

    @media (min-width: 400px) and (max-width: 768px) {
      text-align: center;
      width: 80%;

      span {
        font-size: 3rem;
      }
    }
  }

  .contact__us {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5px;

    span {
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;

      @media screen and (max-width: 768px) {
        span {
          font-size: 1.2rem;
        }
      }

      @media screen and (max-width: 400px) {
        span {
          font-size: 0.9rem;
          /* Adjust other styles as needed for even smaller screens */
        }
      }
    }

    .contact__form {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      input {
        width: 100%; /* Adjusted width to 100% for responsiveness */
        min-width: 300px ; /* Added max-width to limit width on larger screens */
        height: 40px;
        border-radius: 5px;
        border: none;
        outline: none;
        padding: 0 10px;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;

        &::placeholder {
          font-family: Tate;
          font-size: 0.9rem;
        }

        &:focus {
          border: 0.5px solid #fff;
        }

      
      }

      button {
        width: 100%; /* Adjusted width to 100% for responsiveness */
        max-width: 120px; /* Added max-width to limit width on larger screens */
        height: 40px;
        border-radius: 50px;
        border: none;
        outline: none;
        background-color: #fff;
        color: #e85b6a;
        font-weight: bold;
        cursor: pointer;
        font-family: Tate;
        font-size: 1.2rem;
        transition: all 0.3s ease-in-out;

        &:hover {
          border: 1px solid #fff;
          background-color: #e85b6a;
          color: #fff;
        }
      }

      @media (min-width: 400px) and (max-width: 768px) {
        padding: 0 20px;
        text-align: center;
        width: 80%; /* Adjust the width for smaller screens */

        input {
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
