@media (min-width: 300px) and (max-width: 768px) {
  /* Styles for screens between 768px and 991px (e.g., tablets) */
  img {
    width: 40% !important;
  }
 
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Styles for screens between 768px and 991px (e.g., tablets) */
  img {
    width: 75% !important;
  }
 
}

@media (min-width: 992px) and (max-width: 1200px) {
  /* Styles for screens larger than 991px (e.g., desktops) */
  img {
    width: 50% !important;
  }
}


@media (min-width: 1200px) {
  /* Styles for screens larger than 991px (e.g., desktops) */
  img {
    width: 35% !important;
  }
}
