.map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title__container {
    position: absolute;
    top: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 9999;

    .title {
      font-size: 1.2rem;
      font-weight: normal;
      color: black;
    }

    .sub__title {
      font-size: 2rem;
      font-weight: 700;
      color: #e85b6a;
      margin-bottom: 20px;
    }
  }

  .map__container {
    position: relative;
    bottom: 50px;
    max-height: 100vh;
    overflow-y: hidden;
  }

  @media (min-width: 400px) and (max-width: 556px) {
    .title__container {
      top: 80px;

      .title {
        font-size: 1rem;
      }
      .sub__title {
        font-size: 1.4rem;
        padding: 0 40px;
      }
    }

    .map__container {
      // move map to the left 
      // adjust map position
      overflow: hidden;
      .map__svg {
        width: 100%;
        height: 100%;
        scale: 3.5;
        position: relative;
        top: 60px;
        right: 40px;
      }
    }
  }

  @media (min-width: 556px) and (max-width: 992px) {
    .title__container {
      top: 80px;

      .title {
        font-size: 1rem;
      }
      .sub__title {
        font-size: 1.6rem;
        padding: 0 40px;
      }
    }

    .map__container {
      overflow: hidden;

      .map__svg {
        width: 100%;
        height: 100%;
        scale: 1.7;
        position: relative;
        top: 10px;
        right: 40px;
        // hide loock vertical scroll
      }
    }
  }

  
  @media (min-width: 1260px) and (max-height:1280px) {
    .title__container {
      top: 80px;

      .title {
        font-size: 1rem;
      }
      .sub__title {
        font-size: 1.6rem;
        padding: 0 40px;
      }
    }

    .map__container {
      overflow: hidden;

      .map__svg {
        width: 100%;
        height: 100%;
        scale: 1;
        position: relative;
        top: 20px;
        right: 40px;
        // hide loock vertical scroll
      }
    }
  }

  @media (min-width: 1281){
    .title__container {
      top: 140px;

      .title {
        font-size: 1.2rem;
      }
      .sub__title {
        font-size: 2.5rem;
        padding: 0 40px;
      }
    }

    .map__container {
      overflow: hidden;
      .map__svg {
        width: 100%;
        height: 100%;
        scale: 1.4;
        position: relative;
        top: 40px;
        right: 40px;
        // hide loock vertical scroll
      }
    }
  }

}
